.main {
    display: grid;
    grid-template-columns: 20% 20% 15% 15% auto;
    grid-template-rows: 1fr;
    width: 100%;
    overflow: hidden;
    padding: 20px 0;
    border-bottom: solid 1px gray;
}

.header {
    font-weight: bold;
}

.sort {
    display: flex;
    gap: 10px;
}