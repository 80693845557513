.drop_zone {
  display: grid;
  border-radius: 25px;
  width: 100%;
  /* background-color: black; */
  border-style: dotted;
  box-sizing: border-box;
  justify-self: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px 0 #00000024,0 1px 5px 0 #0000001f;
  font-size: 25px;
  color: rgb(169, 169, 169);
  margin: 5px 10px 25px 10px;
  overflow: hidden;
}

.drop_zone section {
  height: 100%;
  width: 93vw;
  display: grid;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.drop_zone section div {
  height: 100%;
  width: 93vw;
  display: grid;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.drop_zone img {
  height: 100%;
}