.list {
  height: 100%;
  align-self: stretch;
  overflow: hidden;  
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
}

.list div:nth-child(2) {
  overflow-x: hidden;
  overflow-y: auto;  
}