.leftContainer {
	width: calc(45% - 10px);
	height: 100%;
}

.styleBlock {
	margin-top: 30px;
	height: 40%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: space-between;
}

.styleBlock p {
	margin-top: 30px;
	margin-bottom: 30px;
}

.leftblock {
	box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
	height: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.select {
	width: 100%;
	padding: 10px;
	font-size: 16px;
	border: 2px solid #ec971f;
	border-radius: 5px;
	outline: none;
	background-color: #fff;
	height: 60px;
}

.select option {
	background-color: #f4f4f4;
	color: #333;
	padding: 8px 12px;
	border: 2px solid #ec971f;
}

.formPosition {
	margin-bottom: 10px;
}

.textareaField {
	width: calc(100% - 20px);
	padding: 8px;
	border: 2px solid #ec971f;
	border-radius: 10px;
	/* height: 9px; */
}

.styledButton {
	margin-top: 20px;
	display: block;
	width: calc(100% - 20px);
	text-transform: uppercase;
}

.disabledButton {
	cursor: not-allowed;
	opacity: 0.6;
}
