.centeredButtonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.centeredButton {
	color: white;
	text-transform: uppercase;
	padding: 15px;
}

.spinnerContainer {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
