.plugin_details {
  display: grid;
  height: 97%;
  max-height: 300px;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;    
}

.file_container {  
  overflow: hidden;
  margin: 0 2px;
  height: 100%;
  box-sizing: border-box;
  max-height: inherit;
  overflow-y: auto;
}

.file_list_container {
  display: grid;
  grid-template-rows: 30px auto;
  overflow: hidden;
}