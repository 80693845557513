.main {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 5px;
    border: 1px solid gray;
    margin: 7px 0;
    border-radius: 7px;
}

.version_line {
    width: 100%;
    display: flex;
    gap: 10px;
    overflow: hidden;
    align-items: center;
    align-content: center;
}

.message_line {
    width: 100%;
    display: flex;
    gap: 10px;
    overflow: hidden;
    align-items: center;
    align-content: center;
}

.text_line {
    width: 90%;
    overflow: hidden;
}

.text_line input {
    width: 100%;
    overflow: hidden;
    padding: 7px;
    border-radius: 7px;
}