.glossary_menu {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content auto;
  overflow: hidden;
  align-items: start;
}

.glossary_heading {
  padding: 10px 0;
}

.glossary_list_heading {
  padding: 7px 0 3px 0;
  font-weight: 600;
  border-bottom: solid 1px gray;
}

.glossary_list {
  width: 100%;
  height: 98%;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  overflow-y: auto;
}

.glossary_list > div {
  padding: 7px 0;
}

.glossary_list > div:hover {
  cursor: pointer;
  background-color: var(--menu-hover-background-colour);
}