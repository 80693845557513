.container {
	display: flex;
	flex-direction: column;
	height: calc(100% - 90px);
}

.searchContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.searchContainer:first-child {
	text-transform: uppercase;
}

.gridContainer {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	flex-grow: 1;
	overflow: auto;
	padding: 10px;
}

.gridItem {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border: 1px solid #ec971f;
	box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
}

.gridItem img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.paginationContainer {
	display: flex;
	justify-content: center;
	border-top: 1px solid #ccc;
	margin-top: auto;
	padding-top: 10px;
}

.modalImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	margin-top: 30px;
	overflow: hidden;
	background-color: #cdcdcd;
	margin-top: 60px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.viewImageButton {
	display: inline-block;
	margin-top: 10px;
	padding: 8px 16px;
	background-color: #007bff;
	color: white;
	text-decoration: none;
	border-radius: 4px;
	font-weight: bold;
}

.viewImageButton:hover {
	background-color: #0056b3;
}
