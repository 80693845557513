.main {
    display: grid;
    grid-template-columns: 70px 200px auto 100px;
    font-size: medium;
    gap: 15px;
    align-items: center;
    padding: 10px 5px;
    border-bottom: solid 1px gray;
    cursor: pointer;
}

.error {
    color: red;
}

.success {
    color: green;
}

.icon {
    font-size: x-large;
}

.mark {
    text-align: center;
}

.heading {
    font-weight: bold;
}