.menu {
  width: 100%;
  height: 100%;
  background-color: var(--menu-background-colour);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px 0 #00000024,0 1px 5px 0 #0000001f;
}

.version {
  align-self: flex-start;
  width: 100%;
  color: gray;
  text-align: center;
  box-sizing: border-box;
  padding: 10px 5px;
  border: black;
}

.version img {
  width: 80%;
  align-self: center;
}

.version span {
  display: block;
  text-align: right;
  width: 100%;  
  padding: 10px 5px;
  box-sizing: border-box;
  color: var(--menu-text-colour);
}
