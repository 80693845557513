.history_container {
  width: 100%;  
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: .8fr 3fr 2fr 2fr 2fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.heading {
  padding-top: 10px;
  font-weight: bold;
}

.history_container span {
  overflow: hidden;
  padding: 5px 0;
}

.success {
  color: green;
}

.fail {
  color: red;
}

.centered {
  text-align: center;
}

.warn {
  color: orange;
}