.themed_btn {
  justify-self: center;
  padding: 15px 40px;
  margin-right: 10px;
  border-radius: 10px;
  width: 250px;
  background-color: var(--background-button-colour);
  border: none;
  color: var(--button-text-colour);
  font-size: medium;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px 0 #00000024,0 1px 5px 0 #0000001f;  
  transition: var(--button-hover-transition);
}

.themed_btn[disabled] {
  opacity: 0.3;
}

.themed_btn:not([disabled]):hover {
  background-color: var(--button-hover-colour);
  box-shadow: var(--button-hover-box-shadow);
  cursor: pointer;
}

.themed_btn[disabled]:hover {
  cursor: not-allowed;
}