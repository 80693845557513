.button_div {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 100px;
  width: 100%;
}

.loading {
  width: 100px;
}