.new_plugin {
  display: grid;
  grid-template-rows: 40px 1fr 1fr 40px;
  grid-template-columns: 100%;
  margin-top: 5px;
  justify-self: center;
  width: 100%;
  height: 98%;
  padding: 20px;
  align-self: flex-start;
  justify-content: left;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px 0 #00000024,0 1px 5px 0 #0000001f;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
}

.new_plugin span {
  font-weight: bold;
  font-size: large;
}

.heading {
  display: flex;
  gap: 15px;
  align-items: center;
  text-transform: uppercase;
}

.back_button {
  font-weight: bold;
  font-size: xx-large;
  color: var(--background-theme-colour);
}