.main {
	width: 100%;
	display: grid;
	grid-template-columns: auto max-content;
	box-sizing: border-box;
	padding: 0 10px 0 0;
	overflow: hidden;
}

.heading {
	font-size: x-large;
}

.main input {
	border-radius: 7px;
	padding: 5px 10px;
}
