.main {
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 50px auto 50px;  
  justify-items: stretch;
  align-items: stretch;
}

.idea {
  background: linear-gradient(to bottom, #03174b, #03a6f0);
}

.ybl {
  background-image: url("/src/assets/ybl-background.png");
}

.fintech {
  background-image: url("/src/assets/fintech-background.png");
}