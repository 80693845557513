.heading {
  display: flex;
  gap: 15px;
  align-items: center;
}

.back_button {
  font-weight: bold;
  font-size: xx-large;
  color: var(--background-theme-colour);
}

.heading span{
  font-size: x-large;
}
