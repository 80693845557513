.main {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    padding: 5px;
    box-sizing: border-box;
}

.rubrics {
    width: 100%;
    display: flex;
    overflow: hidden;
    gap: 5px;
    flex-wrap: wrap;
}