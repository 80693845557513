.main {
    width: 100%;
    display: grid;
    grid-template-columns: 10% auto 5%;
    align-items: center;
    align-content: start;
    grid-template-rows: 1fr;
}

.question {
    justify-self: start;
}

.tooltip {
    justify-self: center;
    font-size: x-large;
    text-align: center;
    width: 100%;
}

.select_input {
    justify-self: start;
    width: 100%;
    max-width: 100%;
}

.text_input input {
    width: 100%;
    padding: 5px;
}