.main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;    
}

.show {
    display: block;
}

.hide {
    display: none;
}

.far_right {
    align-self: flex-end;
}