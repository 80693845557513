.documentation_menu {
  width: 100%;
  height: 98%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content auto;
  overflow: hidden;
}

.guide_heading {
  padding-bottom: 15px;
}

.documentation_view {
  height: 100%;
  overflow-y: auto;
}