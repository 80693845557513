.main {
  width: 45%;
  display: grid;
  grid-template-columns: max-content auto;
  overflow: hidden;
  box-sizing: border-box;
  gap: 10px;
}

.heading {
  font-weight: bold;
}