.header {
  /* background-color: rgb(0, 74, 149); */
  background-color: var(--background-theme-colour);
  color: white;
  display: grid;
  grid-template-columns: 50px auto 100px;
  width: 100vw;
  align-items: center;
}

.menu {
  justify-self: center;  
}

.header span {
  font-size: x-large;
  text-transform: uppercase;
}
