.user_container {
    width: 100%;  
    overflow: hidden;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 4fr 1fr;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 5px 0;
  }
  
  .heading {
    padding-top: 10px;
    font-weight: bold;
  }
  
  .user_container span {
    overflow: hidden;
    padding: 10px 5px;
  }
  
  .success {
    color: green;
  }
  
  .fail {
    color: red;
  }
  
  .centered {
    text-align: center;
  }
  
  .warn {
    color: orange;
  }