.Login {
  width: 100vw;
  height: 100vh;
  background: var(--login-background);
  background-size: contain;
  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow: hidden;
}

.PasswordDiv {
  width: 500px;
  height: 500px;
  padding: 10px 0;
  background-color: var(--login-form-background);
  border-radius: 40px;
  display: grid;
  grid-template-rows: 35% 20% 20% 15% 5%;
  overflow: hidden;
  box-sizing: border-box;
}

.passwordDivLine {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passwordDivLine input[type=text], input[type=password] {
  width: 70%;
  border-radius: 15px;
  height: 50%;
  border: none;
  padding: 0 20px 0 20px;
  font-size: large;
  color: #03174b;
}

.passwordDivLine button {
  width: 40%;
  height: 50%;
  border-radius: 10px;
  background-color: var(--background-button-colour);
  border: none;
  color: var(--button-text-colour);
  font-size: large;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px 0 #00000024,0 1px 5px 0 #0000001f;
  transition: var(--button-hover-transition);
}

.passwordDivLine button:active {
  transform: translateY(2px);
}

.passwordDivLine button:hover {
  background-color: var(--button-hover-colour);
  cursor: pointer;
  box-shadow: var(--button-hover-box-shadow);
}

.passwordDivLine div {
  height: 100%;
  align-self: stretch;
  color: var(--login-loading-gif-colour);
}

.passwordDivLine span {
  color: #f44336;
  font-weight: bolder;
  justify-content: center;
  font-size: large;
}

.Login img {
  width: var(--login-logo-width);
}

.signedIn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  gap: 5px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 0 0 55px;
}
