.main {
    width: 50%;
    overflow: hidden;
    height: max-content;
    display: grid;
    grid-template-columns: 20% auto;
}

.heading {
    font-weight: bold;
}
