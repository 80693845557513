.mainContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	column-gap: 5px;
}

.whiteMainBackground {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.heading {
	display: flex;
	gap: 15px;
	align-items: center;
	text-transform: uppercase;
}

.back_button {
	font-weight: bold;
	font-size: xx-large;
	color: var(--background-theme-colour);
	padding-bottom: 5px;
}

.back_button:hover {
	cursor: pointer;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}
