.children_container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px auto;
  gap: 10px;
  width: 100%;
  height: 98%;
  overflow: hidden;
}

.heading {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 50px auto 100px;
  height: 100%;
  overflow: hidden;
}

.back_button {
  font-weight: bold;
  font-size: xx-large;
  color: var(--background-theme-colour);
}

.heading span{
  font-size: x-large;
}

.heading :nth-child(3) {
  font-size: xx-large;
}

.heading :nth-child(3):hover {
  cursor: pointer;
}