.main {
    min-width: 33%;
    padding: 5px;
    display: grid;
    grid-template-columns: 70% auto;
    gap: 5px;
    box-sizing: border-box;
    overflow: hidden;
}

.header {
    font-weight: bold;
}