.main {
    display: grid;
    grid-template-columns: 20% auto 25px 20%;
    grid-template-rows: 1fr;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.main select {
    padding: 15px;
}