.main {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: start;
  overflow: hidden;
  grid-template-columns: 100%;
}

.rows_1 {
  grid-template-rows: 50px auto;
}

.rows_2 {
  grid-template-rows: 50px max-content auto;
}

.rows_3 {
  grid-template-rows: 50px max-content 1fr 1fr;
}
