.countdownText {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	color: #333;
	animation: pulse 1s infinite alternate;
}

@keyframes pulse {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.05);
	}
}
