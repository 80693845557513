.error {
  width: 100%;
  height: 100%;  
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

.error_heading {
  font-size: 5em;
  text-align: center;
  padding: 0;
}

.error_reason {
  font-size: 2em;
  text-align: center;
  font-weight: bold;
}

.error_description {
  margin-top: 20px;
  font-size: 1.2em;
  text-align: center;
  box-sizing: border-box;
}