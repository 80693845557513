.main {
    width: 100%;
    display: grid;
    grid-template-columns: 10% auto 5%;
    align-items: center;
    grid-template-rows: 1fr;
    align-content: start;
    height: max-content;
}

.question {
    justify-self: start;
}

.tooltip {
    justify-self: center;
    font-size: x-large;
    text-align: center;
    width: 100%;
}

.text_input {
    justify-self: stretch;
    width: 100%;
    height: max-content;
}

.text_input input {
    width: 100%;
    padding: 5px;
}