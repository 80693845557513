.main {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px 10px 0;
  overflow: hidden;
}

.heading {
  font-size: x-large;
}
