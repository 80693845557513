.container {
  width: 100vw;
  display: grid;
  gap: 15px;
  box-sizing: border-box;
  transition: 300ms;
  overflow: hidden;
}

.show {
  grid-template-columns: 95% auto;
}

.hide {
  grid-template-columns: 0px auto;
  gap: 5px;
}

@media only screen and (min-width: 480px) {
  .show {
    grid-template-columns: 250px auto;
  }

  .hide {
    grid-template-columns: 0px auto;
  }
}
