.plugins {
	display: grid;
	width: 100%;
	grid-template-columns: 100%;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.creator_view {
	grid-template-rows: 60px auto;
}

.viewer_view {
	grid-template-rows: auto;
}

.plugins > button {
	justify-self: center;
	padding: 15px 40px;
	margin-right: 10px;
	border-radius: 10px;
	border: none;
	color: var(--button-text-colour);
	font-size: medium;
	box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
}

.list {
	margin-top: 5px;
	height: 98%;
	padding: 20px;
	justify-self: stretch;
	box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
	background-color: white;
	box-sizing: border-box;
	overflow: hidden;
	display: grid;
	grid-template-rows: max-content auto 25px;
	row-gap: 10px;
}

.plugins button:hover {
	background-color: var(--button-hover-colour);
	cursor: pointer;
}

.heading {
	font-size: x-large;
}
