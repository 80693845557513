.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}
.modal {
	width: 70%;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.mainContent {
	display: flex;
	align-items: flex-start;
}
.content {
	width: 40%;
	padding-left: 20px;
}
.modalContent {
	position: relative;
}
.modalContent img {
	width: 60%;
	height: auto;
}
.closeButton {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	font-size: 24px;
	color: #aaa;
}
.buttonGroup {
	display: flex;
	gap: 10px;
	margin-top: 15px;
}

.chevron {
	margin-left: 10px;
	transition: transform 0.3s ease;
}
.chevronUp {
	transform: rotate(180deg);
}
button {
	padding: 10px 15px;
	border-radius: 10px;
	border: none;
	color: #fff;
	font-size: medium;
	background-color: #ec971f;
	box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}
button:hover:not(:disabled) {
	background-color: #d68b1c;
	box-shadow: var(--button-hover-box-shadow);
}

.deleteButton {
	background-color: #dc3545;
}
.deleteButton:hover:not(:disabled) {
	background-color: #c82333;
}
