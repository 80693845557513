.main {
  display: grid;
  width: 100%;
  height: 98%;
  margin-top: 5px;  
  padding: 20px;
  box-shadow: 0 3px 1px -2px #0003,0 2px 2px 0 #00000024,0 1px 5px 0 #0000001f;
  background-color: white;
  box-sizing: border-box;
  align-items: stretch;
  justify-content: stretch;
  justify-items: stretch;
  overflow: hidden;
}