.main {
    width: 100%;
    display: grid;
    grid-template-columns: 10% auto 5%;
    align-items: center;
    grid-template-rows: 1fr;
    align-content: start;
    height: max-content;
}

.question {
    justify-self: start;
}

.tooltip {
    justify-self: center;
    font-size: x-large;
    text-align: center;
    width: 100%;
}

.word_range {
    display: flex;
    justify-self: stretch;
    width: max-content;
    height: max-content;
    justify-content: flex-start;
    gap: 20px;
}

.word_range span {
    padding-right: 10px;
}

.word_range input {
    width: 100px;
    padding: 5px;
}