.middleContainer {
	width: calc(55% - 10px);
	height: 100%;
}

.middleblock {
	box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
	height: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.formPosition h1 {
	margin-bottom: 20px;
}

.ImageContainer {
	width: 100%;
	height: 400px;
	border-radius: 10px;
	border: 2px solid #ec971f;
	box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
	background-color: white;
	/* margin-top: 60px; */
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ImageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.textareaField {
	width: calc(100% - 20px);
	padding: 8px;
	border: 2px solid #ec971f;
	border-radius: 10px;
	resize: none;
}

.styledButton {
	display: block;
	width: 100%;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
}

.disabledButton {
	cursor: not-allowed;
	opacity: 0.6;
}
