.main {
    display: grid;
    width: 100%;
    overflow: hidden;
    margin: 15px 0;
    justify-items: center;
}

.role_group {
    display: grid;
    width: 95%;
    border: 1px solid var(--background-button-colour);
    border-radius: 7px;
    padding: 20px 10px;
    box-sizing: border-box;
    grid-template-columns: 60% 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    overflow: hidden;
}

.save {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    font-size: x-large;
}