.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.main div {
  height: 40px;
  width: 40px;
}

.main div:hover {
  cursor: pointer;
}

.idea {
  background: linear-gradient(to bottom, #03174b, #03a6f0);
}

.ybl {
  background-image: url("/src/assets/ybl-background.png");
}

.fintech {
  background-image: url("/src/assets/fintech-background.png");
}