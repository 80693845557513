.main {
	align-self: center;
	justify-self: center;
}

.main > ul {
	height: 40px;
	overflow: hidden;
}

.main > ul > li {
	height: 40px;
}

.main > ul > li > a,
.main > ul > li > span,
.main > ul > li > a > span {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
	font-size: 15px;
	text-decoration: none;
	cursor: pointer;
}

.main > ul > li > a > span {
	font-size: large;
}
