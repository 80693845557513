.notfound {
  width: 100%;
  height: 100%;  
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

.notfound404 {
  font-size: 5em;
  text-align: center;
  padding: 0;
}

.notfoundpage {
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
}

.notfoundresource {
  margin-top: 20px;
  font-size: 1.2em;
  text-align: center;
  text-transform: capitalize;
  box-sizing: border-box;
}