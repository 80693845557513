.main {
    display: grid;
    grid-template-columns: 1fr;    
    overflow: hidden;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.has_button {
    grid-template-rows: 100px auto;
}

.no_button {
    grid-template-rows: 1fr;
}

.main > button {
    padding: 20px 40px;
}

.main_partition {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px auto 50px;
    height: 100%;
    overflow: hidden;
}