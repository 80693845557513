.main {
    width: 100%;
    overflow: hidden;
    padding: 5px;
    display: grid;
    grid-template-columns: 10% auto;
    gap: 5px;
}

.header {
    font-weight: bold;
    overflow: hidden;
}