.button {
	padding: 10px 7px;
	margin-right: 10px;
	border-radius: 10px;
	background-color: var(--background-button-colour);
	border: none;
	color: var(--button-text-colour);
	font-size: medium;
	box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
	transition: var(--button-hover-transition);
}

.button:hover:not(:disabled) {
	background-color: var(--button-hover-colour);
	cursor: pointer;
	box-shadow: var(--button-hover-box-shadow);
}
