.errorBanner {
	background-color: #ffebee;
	color: #c62828;
	padding: 10px;
	margin: 10px 0;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.errorBanner button {
	background-color: #c62828;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 4px;
	cursor: pointer;
}
