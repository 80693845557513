.main {
  display: grid;
  width: 100%;
  grid-template-columns: auto 50px;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid gray;
}

.main_line {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.normal {
  font-size: 1em;
  padding: 20px 10px 20px 10px;
  box-sizing: border-box;
}

.heading {
  border-bottom: 1px solid rgba(0,0,0,0.8);
}

.heading span {
  width: 100%;
  font-weight: bold;
  font-size: 1.2em;
}

.icon {
  width: 100%;
  display: flex;
  gap: 10px;
}