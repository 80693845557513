.plugin_view {
  display: grid;
  width: 100%;
  overflow-y: auto;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  grid-template-columns: 40% 20% 30% 10%;
  align-items: center;
  overflow-x: hidden;
  box-sizing: border-box;
}

.heading {
  font-size: 1em;
  font-weight: bold;
}

.plugin_view span {
  font-size: 1em;
  overflow: hidden;
  /* background-color: red; */
}

.plugin_view svg {
  justify-self: center;
}