.home_container {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-rows: 70px auto;
  background-color: rgb(229, 231, 230);
  overflow: hidden;
  padding-bottom: 40px;
}

/* Large screens */
@media only screen and (min-width: 480px) {
  .home_container {
    padding-bottom: 0px;
  }
}