.menu {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0 10px 20px;
  text-align: left;
  overflow: hidden;
  color: var(--menu-text-colour);
  text-transform: uppercase;
  font-size: large;
  text-decoration: none;
}

.menu:hover {
  color: var(--menu-hover-text-colour);
  background-color: var(--menu-hover-background-colour);  
  opacity: .8;
  cursor: pointer;
}

.active {
  color: var(--menu-active-text-colour);
  background-color: var(--menu-active-background-colour);
}