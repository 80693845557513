.main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content auto max-content;
    overflow: hidden;
    gap: 10px;
}

.main textarea {
    padding: 10px;
}