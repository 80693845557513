.imageContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

.image {
	max-width: 100%;
	height: auto;
	margin-bottom: 20px;
}

.prompt {
	font-size: 16px;
	text-align: center;
	max-width: 600px;
}

.errorMessage {
	color: red;
	text-align: center;
	font-size: 18px;
}

.heading {
	display: flex;
	gap: 15px;
	align-items: center;
	text-transform: uppercase;
}

.back_button {
	font-weight: bold;
	font-size: xx-large;
	color: var(--background-theme-colour);
	padding-bottom: 5px;
}

.back_button:hover {
	cursor: pointer;
}

.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.buttonContainer {
	display: flex;
	gap: 10px;
	margin-top: 20px;
}

.button {
	padding: 10px 20px;
	font-size: 16px;
	background-color: #d68b1c;
	color: white;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	transition: background-color 0.3s;
}

.button:hover {
	background-color: #d68b1c;
}
