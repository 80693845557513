.glossary_view {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto max-content;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  justify-items: stretch;
  align-items: stretch;
  text-transform: uppercase;
}

.download_div {
  text-align: center;
  font-size: x-large;
}
