.main {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-rows: max-content auto;
}

.list {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}