:root {
  /* Main theme */
  --background-theme-colour: #5C37DF;

  /* Button theme white*/
  --background-button-colour: #00fffb;
  --button-hover-colour: #00fffb;
  --button-text-colour: #1d2570;
  --button-hover-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  --button-hover-box-shadow: 0 5px 3px 2px #0003,0 4px 4px 0 #00000024,0 3px 7px 0 #0000001f;

  /* Menu theme */
  --menu-text-colour: #aaa;
  --menu-hover-text-colour: #e6e6e6;
  --menu-hover-background-colour: #aa80ff;
  --menu-active-background-colour: rgb(170, 128, 255);
  --menu-active-text-colour: rgb(230, 230, 230);
  --menu-background-colour: #5C37DF;

  /* Pagination theme */
  --pagination-background-colour: green;

  /* Spinner icon */
  --loading-gif-colour: #5C37DF;
  --login-loading-gif-colour: #00fffb;

  /** Log in */
  --login-background: rgba(170, 128, 255, 0.2);
  --login-form-background: rgb(92, 55, 223);
  --login-logo-width: 35%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

