.user_right {
    display: grid;
    width: 100%;
    overflow: hidden;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    align-content: center;
}

.user_right input {
    transform: scale(1.5);
}