.main {
    display: grid;
    width: 100%;
    overflow: hidden;
    grid-template-columns: 20% 15% 15% 10% 40%;
    grid-template-rows: 1fr;
    padding: 10px 0;
    border-bottom: 1px solid gray;
}

.heading {
    font-weight: bold;
}